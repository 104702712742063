/**
 * @generated SignedSource<<c9b4bfb459a694be0d3d2cb0f5104030>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SchoolYearTypeEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1" | "high_school_2" | "high_school_3" | "high_school_N" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InternalUserConnectionDataTable_internalUserConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly loginId: string;
      readonly phoneNumber: string | null;
      readonly studentProfile: {
        readonly friendTag: string;
        readonly profileImageUrl: string;
        readonly schoolYearType: SchoolYearTypeEnum;
        readonly statusMessage: string | null;
      };
      readonly userName: string;
      readonly userSequence: number;
    };
  }>;
  readonly " $fragmentType": "InternalUserConnectionDataTable_internalUserConnection";
};
export type InternalUserConnectionDataTable_internalUserConnection$key = {
  readonly " $data"?: InternalUserConnectionDataTable_internalUserConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternalUserConnectionDataTable_internalUserConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternalUserConnectionDataTable_internalUserConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InternalUserEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InternalUser",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "loginId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userSequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InternalStudentProfile",
              "kind": "LinkedField",
              "name": "studentProfile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "schoolYearType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profileImageUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "friendTag",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "statusMessage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalUserConnection",
  "abstractKey": null
};

(node as any).hash = "e2fc54c88e460012ec5aadec8ee79ed7";

export default node;
