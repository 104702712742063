/**
 * @generated SignedSource<<31a796e04277abb180859380f51749db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type ErrorReportFilter = {
  AND?: ErrorReportFilter | null;
  NOT?: ErrorReportFilter | null;
  OR?: ErrorReportFilter | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  nodeId_Exact?: string | null;
  nodeId_In?: ReadonlyArray<string> | null;
};
export type ErrorReportOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
};
export type ErrorReportPaginatorQuery$variables = {
  after?: string | null;
  filters?: ErrorReportFilter | null;
  first?: number | null;
  order?: ErrorReportOrder | null;
};
export type ErrorReportPaginatorQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ErrorReportPaginator_query">;
};
export type ErrorReportPaginatorQuery = {
  response: ErrorReportPaginatorQuery$data;
  variables: ErrorReportPaginatorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ErrorReportPaginatorQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ErrorReportPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ErrorReportPaginatorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ErrorReportConnection",
        "kind": "LinkedField",
        "name": "errorReports",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ErrorReportEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ErrorReport",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reason",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "ErrorReportsPaginator_query_errorReports",
        "kind": "LinkedHandle",
        "name": "errorReports"
      }
    ]
  },
  "params": {
    "cacheID": "fa0898194536c6e6e02bbf295d500e82",
    "id": null,
    "metadata": {},
    "name": "ErrorReportPaginatorQuery",
    "operationKind": "query",
    "text": "query ErrorReportPaginatorQuery(\n  $after: String\n  $filters: ErrorReportFilter\n  $first: Int\n  $order: ErrorReportOrder\n) {\n  ...ErrorReportPaginator_query_2rfEMg\n}\n\nfragment ErrorReportItem_errorReport on ErrorReport {\n  id\n  reason\n  created\n}\n\nfragment ErrorReportPaginator_query_2rfEMg on Query {\n  errorReports(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        ...ErrorReportItem_errorReport\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "17289ba8b0ccfc6cee578adaf4908318";

export default node;
