import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { PremiumDeleteConfirmButton_premium$key } from '../../../relay/__generated__/PremiumDeleteConfirmButton_premium.graphql';
import { PremiumDeleteConfirmButton_premiumDeleteMutation } from '../../../relay/__generated__/PremiumDeleteConfirmButton_premiumDeleteMutation.graphql';
import MutationConfirmButton, { MutationConfirmButtonProps } from '../../core/MutationConfirmButton';

const PremiumDeleteConfirmButton_premium = graphql`
  fragment PremiumDeleteConfirmButton_premium on PremiumApplyHistory {
    id
  }
`;

type Props = {
  premium: PremiumDeleteConfirmButton_premium$key;
} & Pick<
  MutationConfirmButtonProps<PremiumDeleteConfirmButton_premiumDeleteMutation>,
  'config' | 'onSubmit' | 'leadingIcon'
>;

const PremiumDeleteConfirmButton = ({ premium: premiumReference, ...props }: Props) => {
  const premium = useFragment(PremiumDeleteConfirmButton_premium, premiumReference);
  const { id } = premium;
  const { toast } = useToast();
  return (
    <MutationConfirmButton
      variant={'invisible'}
      size={'small'}
      input={{ id }}
      mutation={graphql`
        mutation PremiumDeleteConfirmButton_premiumDeleteMutation($input: InternalPremiumApplyHistoryDeleteInput!) {
          internalPremiumApplyHistoryDelete(input: $input)
        }
      `}
      message={'정말 삭제할까요?'}
      config={{
        onCompleted: () => {
          toast('삭제되었어요.', 'success');
        },
      }}
      {...props}
    >
      삭제
    </MutationConfirmButton>
  );
};

export default PremiumDeleteConfirmButton;
