/**
 * @generated SignedSource<<6e24f5cd96fab4da4992858c39acf560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SchoolYearMajorTypeEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1_common" | "high_school_2_common" | "high_school_3_giha" | "high_school_3_hwaktong" | "high_school_3_mijukbun" | "high_school_n_giha" | "high_school_n_hwaktong" | "high_school_n_mijukbun" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InternalUserSchoolYearMajorTypeUpdateDialog_internalUser$data = {
  readonly id: string;
  readonly studentProfile: {
    readonly schoolYearMajorType: SchoolYearMajorTypeEnum;
  };
  readonly " $fragmentType": "InternalUserSchoolYearMajorTypeUpdateDialog_internalUser";
};
export type InternalUserSchoolYearMajorTypeUpdateDialog_internalUser$key = {
  readonly " $data"?: InternalUserSchoolYearMajorTypeUpdateDialog_internalUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternalUserSchoolYearMajorTypeUpdateDialog_internalUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternalUserSchoolYearMajorTypeUpdateDialog_internalUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InternalStudentProfile",
      "kind": "LinkedField",
      "name": "studentProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schoolYearMajorType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalUser",
  "abstractKey": null
};

(node as any).hash = "13364bc0955d687b1ef7565731c31870";

export default node;
