import { PersonIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import CheckboxGroupField from '../../components/core/CheckboxGroupField';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSelectField from '../../components/core/EnumPairSelectField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import InternalUserConnectionDataTable from '../../components/internalUser/InternalUserConnectionDataTable';
import InternalUserWindowPaginator from '../../components/internalUser/InternalUserWindowPaginator';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import {
  SchoolYearTypeEnum,
  mathKingUser_MathKingUsersQuery,
} from '../../relay/__generated__/mathKingUser_MathKingUsersQuery.graphql';
import { NextPage } from '../_app';

import MathKingUserId from './[mathKingUserId]';

const mathKingUsersForMathKingUser = graphql`
  query mathKingUser_MathKingUsersQuery($filters: InternalUserFilter, $page: Int, $pageSize: Int) {
    ...InternalUserWindowPaginator_query @arguments(filters: $filters, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const MathKingUser: NextPage<Props> = () => {
  const router = useRouter();
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    page: { type: 'number' },
    search: { type: 'string' },
    schoolYearType_Exact: { type: 'string' },
    premium_Exists_In: { type: 'boolean', multiple: true },
  });
  const { page, search, schoolYearType_Exact, premium_Exists_In } = initialValues;
  const isQueryFilterSet = schoolYearType_Exact || premium_Exists_In || search;

  console.log('page: ', page);

  return (
    <View>
      <Head siteTitle={'수학대왕 유저'} />
      <ErrorBoundary>
        <QueryFormik<mathKingUser_MathKingUsersQuery>
          query={mathKingUsersForMathKingUser}
          staticVariables={{ pageSize: 10 }}
          initialValues={{
            filters: {
              search: search || '',
              schoolYearType_Exact: schoolYearType_Exact as SchoolYearTypeEnum | null | undefined,
              premium_Exists_In: premium_Exists_In,
            },
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters }, { scroll: false })}
          enableReinitialize
          enableInitialQueryLoad={isQueryFilterSet ? true : false}
        >
          {({ values: { filters, order }, resetForm, setFieldValue, submitForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>수학대왕 유저</Text>
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'InternalUserFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      size={'large'}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setTimeout(() => submitForm());
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 4]} title={'유저 학년'}>
                      <EnumPairSelectField
                        typename={'SchoolYearTypeEnum'}
                        name={'filters.schoolYearType_Exact'}
                        label={'유저 학년'}
                        placeholder={'선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 2 / 4]} title={'프리미엄 유저'}>
                      <CheckboxGroupField
                        label={'프리미엄 유무'}
                        name={'filters.premium_Exists_In'}
                        options={[
                          { id: 'true', text: '있음' },
                          { id: 'false', text: '없음' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.premium_Exists_In?.map((value) => (value === true ? 'true' : 'false'))}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.premium_Exists_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<mathKingUser_MathKingUsersQuery>>
                        {(queryReference) => (
                          <InternalUserWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, page })}
                          >
                            {({ internalUsers }, { renderPagination }) => (
                              <>
                                <View sx={{ marginTop: 3 }}>
                                  <InternalUserConnectionDataTable
                                    internalUserConnection={internalUsers}
                                    onRowClick={({ id }) => router.push({ pathname: `/mathKingUser/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                    renderHead={(columns) => {
                                      const headCommonStyle = {
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'border.default',
                                        textAlign: 'start',
                                        padding: 2,
                                        fontWeight: 'bold',
                                        color: 'fg.muted',
                                      };
                                      return (
                                        <View
                                          as={'thead'}
                                          sx={{
                                            borderBottomWidth: 1,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'border.default',
                                            backgroundColor: 'canvas.subtle',
                                          }}
                                        >
                                          <View as={'tr'}>
                                            {columns.map(({ field, title, width }) => (
                                              <View
                                                key={field}
                                                as={'th'}
                                                sx={{
                                                  minWidth: width,
                                                  ...headCommonStyle,
                                                }}
                                              >
                                                {title}
                                              </View>
                                            ))}
                                          </View>
                                        </View>
                                      );
                                    }}
                                    renderRow={(row, columns, index) => {
                                      const cellCommonStyle = {
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'border.default',
                                      };

                                      return (
                                        <>
                                          {columns.map(({ field, renderValue, width, align = 'start' }) => (
                                            <View
                                              key={field}
                                              as={'td'}
                                              sx={{
                                                ...cellCommonStyle,
                                                minWidth: width,
                                                textAlign: align,
                                                padding: 2,
                                              }}
                                            >
                                              {renderValue(row, index)}
                                            </View>
                                          ))}
                                        </>
                                      );
                                    }}
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </InternalUserWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

MathKingUser.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
MathKingUser.authenticated = true;
MathKingUser.routes = [
  {
    id: 'mathKingUser',
    icon: PersonIcon,
    pathname: '/mathKingUser',
    name: '수학대왕 유저',
    permissions: ['math_king_user_read'],
  },
  ...MathKingUserId.routes,
];

export default MathKingUser;
