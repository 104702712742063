/**
 * @generated SignedSource<<f1993737c7b9e03666ab0c1cbdf16d79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InternalUserStatusMessageUpdateDialog_internalUser$data = {
  readonly id: string;
  readonly studentProfile: {
    readonly statusMessage: string | null;
  };
  readonly " $fragmentType": "InternalUserStatusMessageUpdateDialog_internalUser";
};
export type InternalUserStatusMessageUpdateDialog_internalUser$key = {
  readonly " $data"?: InternalUserStatusMessageUpdateDialog_internalUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternalUserStatusMessageUpdateDialog_internalUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternalUserStatusMessageUpdateDialog_internalUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InternalStudentProfile",
      "kind": "LinkedField",
      "name": "studentProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statusMessage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalUser",
  "abstractKey": null
};

(node as any).hash = "7e62087fbc9d2e63f8bae399bfeccd94";

export default node;
