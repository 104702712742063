/**
 * @generated SignedSource<<f107f520520fd1c91817b409c496ec14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type UserTeamEnum = "contents" | "designers" | "developers" | "freelancer" | "ga" | "growth" | "%future added value";
export type UserFilter = {
  AND?: UserFilter | null;
  NOT?: UserFilter | null;
  OR?: UserFilter | null;
  groupId_Exact?: string | null;
  groupId_In?: ReadonlyArray<string> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  name_Exact?: string | null;
  name_In?: ReadonlyArray<string> | null;
  search?: string | null;
  team_In?: ReadonlyArray<UserTeamEnum> | null;
};
export type UserOrder = {
  email?: Ordering | null;
  id?: Ordering | null;
  name?: Ordering | null;
};
export type MarkdownEditor_usersQuery$variables = {
  after?: string | null;
  filters?: UserFilter | null;
  first?: number | null;
  order?: UserOrder | null;
};
export type MarkdownEditor_usersQuery$data = {
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly group: {
          readonly id: string;
          readonly title: string;
        } | null;
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};
export type MarkdownEditor_usersQuery = {
  response: MarkdownEditor_usersQuery$data;
  variables: MarkdownEditor_usersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      }
    ],
    "concreteType": "UserConnection",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkdownEditor_usersQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MarkdownEditor_usersQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "c20d2f35476aba00702d9b29bd3f49bf",
    "id": null,
    "metadata": {},
    "name": "MarkdownEditor_usersQuery",
    "operationKind": "query",
    "text": "query MarkdownEditor_usersQuery(\n  $filters: UserFilter\n  $order: UserOrder\n  $first: Int\n  $after: String\n) {\n  users(filters: $filters, order: $order, first: $first, after: $after) {\n    edges {\n      node {\n        id\n        name\n        group {\n          id\n          title\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "027d237f98198a787457daaee75b184b";

export default node;
