/**
 * @generated SignedSource<<5bb05e91bda48621f6cab147e2e1d4d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoryEnum = "ai_test" | "basic" | "developer" | "hidden" | "math_content" | "math_king_user" | "product_event" | "%future added value";
export type UserPermissionsUpdateDialog_groupQuery$variables = {
  id: string;
};
export type UserPermissionsUpdateDialog_groupQuery$data = {
  readonly group: {
    readonly categories: ReadonlyArray<{
      readonly id: string;
      readonly title: CategoryEnum;
      readonly " $fragmentSpreads": FragmentRefs<"CategoryTableItem_category">;
    }>;
    readonly id: string;
  };
};
export type UserPermissionsUpdateDialog_groupQuery = {
  response: UserPermissionsUpdateDialog_groupQuery$data;
  variables: UserPermissionsUpdateDialog_groupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserPermissionsUpdateDialog_groupQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Group",
        "kind": "LinkedField",
        "name": "group",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "categories",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CategoryTableItem_category"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserPermissionsUpdateDialog_groupQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Group",
        "kind": "LinkedField",
        "name": "group",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "categories",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Feature",
                "kind": "LinkedField",
                "name": "features",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Permission",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isActive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "61efbc964c6822bb7727cc2a12609b9d",
    "id": null,
    "metadata": {},
    "name": "UserPermissionsUpdateDialog_groupQuery",
    "operationKind": "query",
    "text": "query UserPermissionsUpdateDialog_groupQuery(\n  $id: ID!\n) {\n  group(id: $id) {\n    id\n    categories {\n      id\n      title\n      ...CategoryTableItem_category\n    }\n  }\n}\n\nfragment CategoryTableItem_category on Category {\n  id\n  features {\n    id\n    title\n    permissions {\n      id\n      isActive\n      label\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29c777aaa38b784bb53e44647665a98a";

export default node;
