import { CheckIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql } from 'react-relay';

import { SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation } from '../../../relay/__generated__/SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import UserAvatarRadioGroupField from '../../user/UserAvatarRadioGroupField';

type Props = {} & DialogProps &
  Pick<
    MutationFormikProps<SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation>,
    'onSubmit' | 'config' | 'connections'
  > &
  Pick<
    MutationFormikProps<SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation>['initialValues'],
    'schoolExamSources'
  >;

const SchoolExamSourceAssignDialog = ({
  isOpen,
  onSubmit,
  config,
  connections,
  schoolExamSources,
  ...props
}: Props) => {
  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>
        <Stack gapX={1}>
          <Stack.Item>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>배정하기</Text>
          </Stack.Item>
          <Stack.Item>
            <Label variant={'accent'}>{schoolExamSources.length}문제</Label>
          </Stack.Item>
        </Stack>
      </Dialog.Header>
      <MutationFormik<SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation>
        mutation={graphql`
          mutation SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation($input: SchoolExamSourceAssignInput!) {
            schoolExamSourceAssign(input: $input) {
              id
              assigned
              assignedTo {
                id
                ...UserAvatarText_user
              }
            }
          }
        `}
        initialValues={{
          assignedTo: '',
          schoolExamSources,
        }}
        onSubmit={onSubmit}
        config={config}
        connections={connections}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <UserAvatarRadioGroupField
                label={'작업자'}
                name={'assignedTo'}
                required
                variables={{ filters: { team_In: ['contents', 'freelancer'] } }}
              />
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} size={'large'} onClick={() => handleSubmit()}>
                배정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default SchoolExamSourceAssignDialog;
export type { Props as SchoolExamSourceAssignDialogProps };
