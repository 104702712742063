/**
 * @generated SignedSource<<4e8a1e17fbe5f30e47f97ad814267ec2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InternalUserNameUpdateInput = {
  id: string;
  userName: string;
};
export type InternalUserNameUpdateDialog_internalUserNameUpdateMutation$variables = {
  input: InternalUserNameUpdateInput;
};
export type InternalUserNameUpdateDialog_internalUserNameUpdateMutation$data = {
  readonly internalUserNameUpdate: {
    readonly id: string;
    readonly userName: string;
  };
};
export type InternalUserNameUpdateDialog_internalUserNameUpdateMutation = {
  response: InternalUserNameUpdateDialog_internalUserNameUpdateMutation$data;
  variables: InternalUserNameUpdateDialog_internalUserNameUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InternalUser",
    "kind": "LinkedField",
    "name": "internalUserNameUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternalUserNameUpdateDialog_internalUserNameUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternalUserNameUpdateDialog_internalUserNameUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0ed41f8b37fe3bc0a699baae1d6291e8",
    "id": null,
    "metadata": {},
    "name": "InternalUserNameUpdateDialog_internalUserNameUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation InternalUserNameUpdateDialog_internalUserNameUpdateMutation(\n  $input: InternalUserNameUpdateInput!\n) {\n  internalUserNameUpdate(input: $input) {\n    id\n    userName\n  }\n}\n"
  }
};
})();

(node as any).hash = "31a7aba0c10bff153e6c84beab156f07";

export default node;
