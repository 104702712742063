import { PencilIcon, PlusIcon, TrashIcon } from '@primer/octicons-react';
import { RefreshIcon } from '@teamturing/icons';
import { useRouter } from 'next/router';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../../components/core/Button';
import Card from '../../../components/core/Card';
import DialogButton from '../../../components/core/DialogButton';
import EmptyState from '../../../components/core/EmptyState';
import ErrorBoundary from '../../../components/core/ErrorBoundary';
import Grid from '../../../components/core/Grid';
import Head from '../../../components/core/Head';
import HorizontalDivider from '../../../components/core/HorizontalDivider';
import IconButton from '../../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../../components/core/Layout';
import QueryFormik from '../../../components/core/QueryFormik';
import Spinner from '../../../components/core/Spinner';
import Stack from '../../../components/core/Stack';
import Text from '../../../components/core/Text';
import View from '../../../components/core/View';
import InternalLoginTypeUpdateDialog from '../../../components/internalUser/InternalLoginTypeUpdateDialog';
import InternalUserDescriptionList from '../../../components/internalUser/InternalUserDescriptionList';
import PremiumApplyHistoryConnectionDataTable from '../../../components/premium/PremiumConnectionDataTable';
import PremiumApplyHistoryCreateDialog from '../../../components/premium/PremiumCreateDialog';
import PremiumDeleteConfirmButton from '../../../components/premium/PremiumDeleteConfirmButton';
import PremiumPaginator from '../../../components/premium/PremiumPaginator';
import PremiumUpdateDialog from '../../../components/premium/PremiumUpdateDialog';
import UserInvoiceConnectionDataTable from '../../../components/userInvoice/UserInvoiceConnectionDataTable';
import UserInvoicePaginator from '../../../components/userInvoice/UserInvoicePaginator';
import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import useToast from '../../../hooks/useToast';
import { MathKingUserId_mathKingUserQuery } from '../../../relay/__generated__/MathKingUserId_mathKingUserQuery.graphql';
import { MathKingUserId_premiumsQuery } from '../../../relay/__generated__/MathKingUserId_premiumsQuery.graphql';
import { MathKingUserId_userInvoicesQuery } from '../../../relay/__generated__/MathKingUserId_userInvoicesQuery.graphql';
import { numberWithCommas } from '../../../utils/number';
import { NextPage } from '../../_app';

const mathKingUserForMathKingUserId = graphql`
  query MathKingUserId_mathKingUserQuery($id: ID!) {
    internalUser(id: $id) {
      id
      userSequence
      userName
      loginId
      phoneNumber
      studentProfile {
        schoolYearType
        profileImageUrl
        friendTag
        statusMessage
      }
      ...InternalUserDescriptionList_internalUser
      ...InternalLoginTypeUpdateDialog_internalUser
    }
  }
`;

const userInvoicesForMathKingUserId = graphql`
  query MathKingUserId_userInvoicesQuery(
    $filters: UserInvoiceFilter
    $order: UserInvoiceOrder
    $first: Int
    $after: String
  ) {
    ...UserInvoicePaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

const premiumsForMathKingUserId = graphql`
  query MathKingUserId_premiumsQuery(
    $filters: PremiumApplyHistoryFilter
    $order: PremiumApplyHistoryOrder
    $first: Int
    $after: String
  ) {
    ...PremiumPaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

type Props = {};

const MathKingUserId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const [{ internalUser }] = useLazyLoadQuery<MathKingUserId_mathKingUserQuery>(mathKingUserForMathKingUserId, {
    id: router.query.mathKingUserId as string,
  });
  if (!internalUser) return null;
  const { userSequence, userName, id } = internalUser;

  return (
    <View>
      <Head siteTitle={`수학 대왕 유저 - ${userSequence}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>
              {userSequence} {userName}
            </Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <DialogButton
              leadingIcon={PencilIcon}
              variant={'primary'}
              size={'large'}
              renderDialog={({ isOpen, closeDialog }) => (
                <InternalLoginTypeUpdateDialog
                  internalUser={internalUser}
                  isOpen={isOpen}
                  onDismiss={closeDialog}
                  config={{
                    onCompleted: () => {
                      closeDialog();
                      toast('ID/PW 변경을 완료했어요', 'success');
                    },
                  }}
                />
              )}
            >
              ID/PW 변경
            </DialogButton>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 1]}>
            <View>
              <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>유저 정보</Text>
              <Card sx={{ marginTop: 1, padding: 2 }}>
                <InternalUserDescriptionList
                  internalUser={internalUser}
                  type="user-info"
                  descriptionUnitSize={[1, 1, 1]}
                />
              </Card>
            </View>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1]}>
            <View sx={{ marginTop: 5 }}>
              <QueryFormik<MathKingUserId_userInvoicesQuery>
                query={userInvoicesForMathKingUserId}
                staticVariables={{ first: 5 }}
                initialValues={{
                  filters: {
                    userId_Exact: id,
                    isPaid: true,
                  },
                  order: '-order_PaidAt',
                }}
                options={{ fetchPolicy: 'store-and-network' }}
                enableReinitialize
              >
                {(_, queryReference) => {
                  return (
                    <ErrorBoundary key={queryReference?.fetchKey}>
                      <Suspense fallback={<Spinner />}>
                        <QueryFormik.PreloadedQueryRenderer<MathKingUserId_userInvoicesQuery>>
                          {(innerQueryReference) => (
                            <UserInvoicePaginator fragmentReference={innerQueryReference}>
                              {({ userInvoices }, { refetch, isLoadingNext, hasNext, loadMore }) => (
                                <>
                                  <Stack>
                                    <Stack.Item>
                                      <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>결제 정보</Text>
                                    </Stack.Item>
                                    <Stack.Item>
                                      <IconButton
                                        size={'small'}
                                        variant={'invisible'}
                                        icon={RefreshIcon}
                                        aria-label={'새로고침'}
                                        onClick={() => {
                                          refetch({}, { fetchPolicy: 'store-and-network' });
                                        }}
                                      />
                                    </Stack.Item>
                                  </Stack>
                                  <View>
                                    <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted', marginTop: 3 }}>
                                      총 {numberWithCommas(userInvoices.totalCount || 0)}건
                                    </Text>
                                  </View>
                                  <View sx={{ marginTop: 3 }}>
                                    <UserInvoiceConnectionDataTable
                                      UserInvoiceConnection={userInvoices}
                                      emptyState={
                                        <View sx={{ paddingY: 3 }}>
                                          <EmptyState title={'결제 내역이 없어요'} description={'결제 내역이 없어요'} />
                                        </View>
                                      }
                                    />
                                  </View>
                                  <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                    {hasNext ? (
                                      <Button
                                        onClick={() => loadMore(3)}
                                        disabled={isLoadingNext}
                                        sx={{ width: '100%', marginTop: 1 }}
                                      >
                                        더보기
                                      </Button>
                                    ) : null}
                                  </View>
                                </>
                              )}
                            </UserInvoicePaginator>
                          )}
                        </QueryFormik.PreloadedQueryRenderer>
                      </Suspense>
                    </ErrorBoundary>
                  );
                }}
              </QueryFormik>
            </View>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1]}>
            <QueryFormik<MathKingUserId_premiumsQuery>
              query={premiumsForMathKingUserId}
              staticVariables={{ first: 5 }}
              initialValues={{
                filters: {
                  userId_Exact: id,
                  isValid: true,
                },
                order: '-endAt',
              }}
              options={{ fetchPolicy: 'store-and-network' }}
              enableReinitialize
            >
              {(_, queryReference) => {
                return (
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<MathKingUserId_premiumsQuery>>
                        {(queryReference) => (
                          <PremiumPaginator fragmentReference={queryReference}>
                            {({ premiums }, { refetch, isLoadingNext, hasNext, loadMore }) => (
                              <>
                                <Stack>
                                  <Stack.Item>
                                    <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>이용권 정보</Text>
                                  </Stack.Item>
                                  <Stack.Item>
                                    <IconButton
                                      size={'small'}
                                      variant={'invisible'}
                                      icon={RefreshIcon}
                                      aria-label={'새로고침'}
                                      onClick={() => {
                                        refetch({}, { fetchPolicy: 'store-and-network' });
                                      }}
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <DialogButton
                                      leadingIcon={PlusIcon}
                                      variant={'invisible'}
                                      size={'small'}
                                      renderDialog={({ isOpen, closeDialog }) => (
                                        <PremiumApplyHistoryCreateDialog
                                          initialValues={{
                                            userId: id,
                                          }}
                                          isOpen={isOpen}
                                          onDismiss={closeDialog}
                                          config={{
                                            onCompleted: () => {
                                              closeDialog();
                                              toast('이용권 생성을 완료했어요', 'success');
                                              refetch({}, { fetchPolicy: 'store-and-network' });
                                            },
                                          }}
                                        />
                                      )}
                                    >
                                      생성
                                    </DialogButton>
                                  </Stack.Item>
                                </Stack>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted', marginTop: 3 }}>
                                    총 {numberWithCommas(premiums.totalCount || 0)}건
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <PremiumApplyHistoryConnectionDataTable
                                    PremiumApplyHistoryConnection={premiums}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'이용권 내역이 없어요'}
                                          description={'이용권 내역이 없어요'}
                                        />
                                      </View>
                                    }
                                    renderHead={(columns) => {
                                      const headCommonStyle = {
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'border.default',
                                        textAlign: 'start',
                                        padding: 2,
                                        fontWeight: 'bold',
                                        color: 'fg.muted',
                                      };
                                      return (
                                        <View
                                          as={'thead'}
                                          sx={{
                                            borderBottomWidth: 1,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'border.default',
                                            backgroundColor: 'canvas.subtle',
                                          }}
                                        >
                                          <View as={'tr'}>
                                            {columns.map(({ field, title, width }) => (
                                              <View
                                                key={field}
                                                as={'th'}
                                                sx={{
                                                  minWidth: width,
                                                  ...headCommonStyle,
                                                }}
                                              >
                                                {title}
                                              </View>
                                            ))}
                                            <View as={'th'} sx={{ ...headCommonStyle, minWidth: 48 }}>
                                              수정
                                            </View>
                                            <View as={'th'} sx={{ ...headCommonStyle, minWidth: 48 }}>
                                              삭제
                                            </View>
                                          </View>
                                        </View>
                                      );
                                    }}
                                    renderRow={(row, columns, index) => {
                                      const cellCommonStyle = {
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'border.default',
                                      };
                                      return (
                                        <>
                                          {columns.map(({ field, renderValue, width, align = 'start' }) => (
                                            <View
                                              key={field}
                                              as={'td'}
                                              sx={{
                                                ...cellCommonStyle,
                                                minWidth: width,
                                                textAlign: align,
                                                padding: 2,
                                              }}
                                            >
                                              {renderValue(row, index)}
                                            </View>
                                          ))}
                                          <View
                                            as={'td'}
                                            sx={{ ...cellCommonStyle, paddingX: '12px' }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <DialogButton
                                              leadingIcon={PencilIcon}
                                              variant={'invisible'}
                                              size={'small'}
                                              renderDialog={({ isOpen, closeDialog }) => (
                                                <PremiumUpdateDialog
                                                  premium={row}
                                                  isOpen={isOpen}
                                                  onDismiss={closeDialog}
                                                  config={{
                                                    onCompleted: () => {
                                                      closeDialog();
                                                      refetch({}, { fetchPolicy: 'store-and-network' });
                                                      toast('이용권 수정을 완료했어요', 'success');
                                                    },
                                                  }}
                                                />
                                              )}
                                            >
                                              수정
                                            </DialogButton>
                                          </View>
                                          <View
                                            as={'td'}
                                            sx={{ ...cellCommonStyle, paddingX: '12px' }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <PremiumDeleteConfirmButton
                                              premium={row}
                                              config={{
                                                onCompleted: () => {
                                                  refetch({}, { fetchPolicy: 'store-and-network' });
                                                  toast('이용권 삭제를 완료했어요', 'success');
                                                },
                                              }}
                                              leadingIcon={TrashIcon}
                                            />
                                          </View>
                                        </>
                                      );
                                    }}
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {hasNext ? (
                                    <Button
                                      onClick={() => loadMore(3)}
                                      disabled={isLoadingNext}
                                      sx={{ width: '100%', marginTop: 1 }}
                                    >
                                      더보기
                                    </Button>
                                  ) : null}
                                </View>
                              </>
                            )}
                          </PremiumPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                );
              }}
            </QueryFormik>
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

MathKingUserId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
MathKingUserId.authenticated = true;
MathKingUserId.routes = [
  {
    id: 'mathKingUserId',
    pathname: '/mathKingUser/[mathKingUserId]',
    name: '수학 대왕 유저 상세',
    permissions: ['math_king_user_read'],
  },
];

export default MathKingUserId;
