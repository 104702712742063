/**
 * @generated SignedSource<<04e6282a9b95d6e1825b91d85a6113d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InternalUserNameUpdateDialog_internalUser$data = {
  readonly id: string;
  readonly userName: string;
  readonly " $fragmentType": "InternalUserNameUpdateDialog_internalUser";
};
export type InternalUserNameUpdateDialog_internalUser$key = {
  readonly " $data"?: InternalUserNameUpdateDialog_internalUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternalUserNameUpdateDialog_internalUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternalUserNameUpdateDialog_internalUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userName",
      "storageKey": null
    }
  ],
  "type": "InternalUser",
  "abstractKey": null
};

(node as any).hash = "77a0dc52acff1ae89c11f4ae8178f4cc";

export default node;
