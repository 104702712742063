/**
 * @generated SignedSource<<93f917da8403363f96cb3d963c56d7c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTypeEnum = "first_final" | "first_mid" | "second_final" | "second_mid" | "%future added value";
export type ExamYearEnum = "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "%future added value";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type SchoolExamSourceStatusEnum = "published" | "scrap" | "upload_required" | "%future added value";
export type SchoolExamSourceStudentTypeEnum = "high_first" | "middle_first" | "middle_second" | "middle_third" | "%future added value";
export type SchoolRegionEnum = "busan" | "chungbuk" | "chungnam" | "daegu" | "daejeon" | "gangwon" | "gwangju" | "gyeongbuk" | "gyeonggi" | "gyeongnam" | "incheon" | "jeju" | "jeongbuk" | "jeonnam" | "sejong" | "seoul" | "ulsan" | "%future added value";
export type SchoolYearEnum = "high_1" | "high_2" | "high_3" | "middle_1" | "middle_2" | "middle_3" | "%future added value";
export type SchoolExamSourceFilter = {
  AND?: SchoolExamSourceFilter | null;
  DISTINCT?: boolean | null;
  NOT?: SchoolExamSourceFilter | null;
  OR?: SchoolExamSourceFilter | null;
  assignedToId_Exact?: string | null;
  assignedToId_In?: ReadonlyArray<string> | null;
  assignedTo_Exists?: boolean | null;
  assignedTo_Exists_In?: ReadonlyArray<boolean> | null;
  completedById_In?: ReadonlyArray<string> | null;
  completedBy_Exists_In?: ReadonlyArray<boolean> | null;
  created_Gte?: any | null;
  created_Lte?: any | null;
  examType_In?: ReadonlyArray<ExamTypeEnum> | null;
  examYear_In?: ReadonlyArray<ExamYearEnum> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  innerPdf_Exists_In?: ReadonlyArray<boolean> | null;
  modified_Gte?: any | null;
  modified_Lte?: any | null;
  pdf_Exists_In?: ReadonlyArray<boolean> | null;
  region_In?: ReadonlyArray<SchoolRegionEnum> | null;
  schoolId_In?: ReadonlyArray<string> | null;
  schoolId_Not_In?: ReadonlyArray<string> | null;
  schoolYear_In?: ReadonlyArray<SchoolYearEnum> | null;
  search?: string | null;
  status_In?: ReadonlyArray<SchoolExamSourceStatusEnum> | null;
  studentType_In?: ReadonlyArray<SchoolExamSourceStudentTypeEnum> | null;
};
export type SchoolExamSourceOrder = {
  completed?: Ordering | null;
  created?: Ordering | null;
  examYear?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
  school?: Ordering | null;
  school_Title?: Ordering | null;
};
export type SchoolExamSourceWindowPaginatorRefreshQuery$variables = {
  filters?: SchoolExamSourceFilter | null;
  order?: SchoolExamSourceOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type SchoolExamSourceWindowPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceWindowPaginator_query">;
};
export type SchoolExamSourceWindowPaginatorRefreshQuery = {
  response: SchoolExamSourceWindowPaginatorRefreshQuery$data;
  variables: SchoolExamSourceWindowPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "picture",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeleted",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolExamSourceWindowPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SchoolExamSourceWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchoolExamSourceWindowPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamSourceConnection",
        "kind": "LinkedField",
        "name": "schoolExamSources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolExamSourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SchoolExamSource",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "School",
                    "kind": "LinkedField",
                    "name": "school",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "region",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "innerPdf",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "assignedTo",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "completedBy",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e26d8a22da9e79792b5fe7ee38c1393",
    "id": null,
    "metadata": {},
    "name": "SchoolExamSourceWindowPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query SchoolExamSourceWindowPaginatorRefreshQuery(\n  $filters: SchoolExamSourceFilter\n  $order: SchoolExamSourceOrder\n  $page: Int = 1\n  $pageSize: Int = 20\n) {\n  ...SchoolExamSourceWindowPaginator_query_36liK3\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment SchoolExamSourceConnectionDataTable_schoolExamSourceConnection on SchoolExamSourceConnection {\n  edges {\n    node {\n      id\n      sequence\n      ...SchoolExamSourceStatusLabel_schoolExamSource\n      school {\n        id\n        title\n        region\n      }\n      title\n      innerPdf {\n        id\n      }\n      assignedTo {\n        id\n        ...UserAvatarText_user\n      }\n      completedBy {\n        id\n        ...UserAvatarText_user\n      }\n    }\n  }\n}\n\nfragment SchoolExamSourceStatusLabel_schoolExamSource on SchoolExamSource {\n  status\n}\n\nfragment SchoolExamSourceWindowPaginator_query_36liK3 on Query {\n  schoolExamSources(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...SchoolExamSourceConnectionDataTable_schoolExamSourceConnection\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "d46fe332cbd14bbb12fdd072c051f916";

export default node;
