import { graphql } from 'react-relay';

import { PremiumPaginator_query$key } from '../../../relay/__generated__/PremiumPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const PremiumPaginator_query = graphql`
  fragment PremiumPaginator_query on Query
  @argumentDefinitions(
    filters: { type: PremiumApplyHistoryFilter, defaultValue: { isValid: true } }
    order: { type: PremiumApplyHistoryOrder, defaultValue: { endAt: DESC } }
    first: { type: Int, defaultValue: 5 }
    after: { type: String }
  )
  @refetchable(queryName: "PremiumPaginatorRefreshQuery") {
    premiums(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "PremiumPaginator_query_premiums") {
      __id
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          startAt
          endAt
          item {
            name
            premiumType
          }
        }
      }
      ...PremiumConnectionDataTable_PremiumApplyHistoryConnection
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<PremiumPaginator_query$key>, 'fragment'>;

const PremiumPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={PremiumPaginator_query} {...props} />;

export default PremiumPaginator;
