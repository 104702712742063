import { format } from 'date-fns';
import { graphql } from 'react-relay';

import { PremiumCreateDialog_premiumCreateMutation } from '../../../relay/__generated__/PremiumCreateDialog_premiumCreateMutation.graphql';
import Button from '../../core/Button';
import DateField from '../../core/DateField';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

type Props = {
  initialValues?: Partial<MutationFormikProps<PremiumCreateDialog_premiumCreateMutation>['initialValues']>;
} & DialogProps &
  Pick<MutationFormikProps<PremiumCreateDialog_premiumCreateMutation>, 'config' | 'onSubmit'>;

const PremiumCreateDialog = ({ onSubmit, config, initialValues, ...props }: Props) => {
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>이용권 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<PremiumCreateDialog_premiumCreateMutation>
        mutation={graphql`
          mutation PremiumCreateDialog_premiumCreateMutation($input: InternalPremiumApplyHistoryCreateInput!) {
            internalPremiumApplyHistoryCreate(input: $input) {
              id
              startAt
              endAt
              created
              item {
                id
                name
                premiumType
              }
              user {
                id
              }
            }
          }
        `}
        initialValues={{
          userId: '',
          startAt: format(new Date(), 'yyyy-MM-dd'),
          endAt: format(new Date(), 'yyyy-MM-dd'),
          itemName: '',
          premiumType: 'premium_refundable',
          ...initialValues,
        }}
        onSubmit={onSubmit}
        config={config}
      >
        {({ submitForm, setFieldValue, dirty }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <DateField
                  label={'시작일'}
                  name={'startAt'}
                  onChange={(value) => setFieldValue('endAt', format(value, 'yyyy-MM-dd'))}
                  required
                />
                <DateField
                  label={'종료일'}
                  name={'endAt'}
                  onChange={(value) => setFieldValue('endAt', format(value, 'yyyy-MM-dd'))}
                  required
                />
                <TextField label={'이용권명'} name={'itemName'} required />
                <EnumPairSelectField
                  typename={'PremiumTypeEnum'}
                  name={'premiumType'}
                  label={'이용권 타입'}
                  required
                  placeholder={'이용권 타입 선택'}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button onClick={submitForm} disabled={!dirty}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default PremiumCreateDialog;
