/**
 * @generated SignedSource<<a9d26d55433050dde3293a361a5d39d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PremiumTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PremiumConnectionDataTable_PremiumApplyHistoryConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly endAt: any;
      readonly id: string;
      readonly item: {
        readonly name: string;
        readonly premiumType: PremiumTypeEnum;
      };
      readonly startAt: any;
      readonly " $fragmentSpreads": FragmentRefs<"PremiumDeleteConfirmButton_premium" | "PremiumUpdateDialog_premium">;
    };
  }>;
  readonly " $fragmentType": "PremiumConnectionDataTable_PremiumApplyHistoryConnection";
};
export type PremiumConnectionDataTable_PremiumApplyHistoryConnection$key = {
  readonly " $data"?: PremiumConnectionDataTable_PremiumApplyHistoryConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"PremiumConnectionDataTable_PremiumApplyHistoryConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PremiumConnectionDataTable_PremiumApplyHistoryConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PremiumApplyHistoryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PremiumApplyHistory",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InternalItem",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "premiumType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PremiumUpdateDialog_premium"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PremiumDeleteConfirmButton_premium"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PremiumApplyHistoryConnection",
  "abstractKey": null
};

(node as any).hash = "9ed4e920cd579b50e69ff9a5cc21bde9";

export default node;
