/**
 * @generated SignedSource<<4d27393ab24291baef224fdb54f36e85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CategoryEnum = "ai_test" | "basic" | "developer" | "hidden" | "math_content" | "math_king_user" | "product_event" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GroupCategoryDialog_group$data = {
  readonly categories: ReadonlyArray<{
    readonly id: string;
    readonly title: CategoryEnum;
    readonly " $fragmentSpreads": FragmentRefs<"CategoryTableItem_category">;
  }>;
  readonly id: string;
  readonly " $fragmentType": "GroupCategoryDialog_group";
};
export type GroupCategoryDialog_group$key = {
  readonly " $data"?: GroupCategoryDialog_group$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupCategoryDialog_group">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupCategoryDialog_group",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categories",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CategoryTableItem_category"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Group",
  "abstractKey": null
};
})();

(node as any).hash = "a7ce02af81f8a6609c391cd344db6669";

export default node;
