import { CopyIcon, DotFillIcon, IconProps, ThreeBarsIcon } from '@primer/octicons-react';
import { ActionMenu, useTheme } from '@primer/react';
import { Space } from '@teamturing/react-kit';
import { clipboard } from '@teamturing/utils';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { FunctionComponent, PropsWithChildren, Suspense, useState } from 'react';
import { graphql } from 'react-relay';

import logo from '../../../../public/logo.png';
import { authenticatedRouteGroups } from '../../../constants/routes';
import { HeaderSidebarLayoutContextProvider } from '../../../contexts/HeaderSidebarLayoutContext';
import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import useToast from '../../../hooks/useToast';
import {
  HeaderSidebarNavPageLayout_meQuery,
  PermissionEnum,
} from '../../../relay/__generated__/HeaderSidebarNavPageLayout_meQuery.graphql';
import { isArray, isNullable } from '../../../utils/is';
import NotificationHeaderItem from '../../notification/NotificationHeaderItem';
import ActionList from '../ActionList';
import Avatar, { AvatarProps } from '../Avatar';
import Button from '../Button';
import CopyableText from '../CopyableText';
import CounterLabel from '../CounterLabel';
import Dialog from '../Dialog';
import DialogHandler from '../DialogHandler';
import Formik from '../Formik';
import Grid from '../Grid';
import Header from '../Header';
import IconButton from '../IconButton';
import Image from '../Image';
import ItemList from '../ItemList';
import Label from '../Label';
import Link from '../Link';
import PageLayout from '../PageLayout';
import Stack from '../Stack';
import StyledOcticon from '../StyledOcticon';
import Text from '../Text';
import UploadField from '../UploadField';
import View from '../View';

const meForHeaderSidebarNavPageLayout = graphql`
  query HeaderSidebarNavPageLayout_meQuery {
    me {
      id
      picture

      permissions {
        title
      }
    }
  }
`;

const HeaderSidebarNavPageLayout = ({ children }: PropsWithChildren) => {
  const { theme } = useTheme();
  const router = useRouter();
  const { toast } = useToast();
  const [{ me }] = useLazyLoadQuery<HeaderSidebarNavPageLayout_meQuery>(meForHeaderSidebarNavPageLayout, {});

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const breakPoint = Number(theme?.breakpoints[1].split('px')[0]);
    return window.innerWidth < breakPoint;
  });
  const toggleIsCollapsed = () => setIsSidebarCollapsed(!isSidebarCollapsed);

  const isDevelopment = process.env.NODE_ENV === 'development';
  const isPreview = process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';

  return (
    <HeaderSidebarLayoutContextProvider value={{ isSidebarCollapsed, setIsSidebarCollapsed, ...LAYOUT_CONSTANTS }}>
      <PageLayout padding={'none'} containerWidth={'full'} columnGap={'none'} rowGap={'none'}>
        <PageLayout.Header sx={{ position: 'sticky', top: 0, zIndex: 3 }}>
          <Header
            sx={{
              backgroundColor: 'canvas.default',
              borderBottomWidth: 1,
              borderBottomColor: [
                isSidebarCollapsed ? 'border.default' : 'canvas.default',
                undefined,
                'border.default',
              ],
              borderBottomStyle: 'solid',
            }}
          >
            <Header.Item>
              <IconButton
                icon={ThreeBarsIcon}
                aria-label={'toggle sidebar'}
                size={'small'}
                variant={'plain'}
                onClick={toggleIsCollapsed}
              />
            </Header.Item>
            <Header.Item full>
              <Stack gapX={1}>
                <Stack.Item>
                  <Image src={logo} alt={logo.src} width={24} height={24} style={{ display: 'block' }} />
                </Stack.Item>
                <Stack.Item>
                  <Text fontWeight={'bold'} fontSize={2} color={'fg.muted'}>
                    TCMS
                  </Text>
                </Stack.Item>
                <Stack.Item>
                  {isDevelopment ? (
                    <Label variant={'severe'}>Development</Label>
                  ) : isPreview ? (
                    <Label variant={'attention'}>Preview</Label>
                  ) : null}
                </Stack.Item>
              </Stack>
            </Header.Item>
            <Header.Item>
              <NotificationHeaderItem />
            </Header.Item>
            <Header.Item>
              <ActionMenu>
                <ActionMenu.Anchor>
                  <Avatar src={me.picture || ''} size={24} sx={{ cursor: 'pointer' }} />
                </ActionMenu.Anchor>
                <DialogHandler
                  renderDialog={({ isOpen, closeDialog }) => (
                    <Dialog wide={true} isOpen={isOpen} onDismiss={closeDialog}>
                      <Dialog.Header>
                        <Text fontSize={2} fontWeight={'bold'}>
                          파일 url 생성하기
                        </Text>
                        <View>
                          <Text fontSize={0} color={'fg.muted'}>
                            {'파일을 업로드하면 url이 복사돼요'}
                          </Text>
                        </View>
                      </Dialog.Header>
                      <Dialog.Body>
                        <Formik<{
                          file: { objectUrl?: string }[] | null;
                        }>
                          initialValues={{ file: null }}
                          onSubmit={() => {}}
                        >
                          {({ values }) => {
                            const copyAllUrl = (urls: string[]) => {
                              const copiedText = urls.join('\n');
                              clipboard
                                .writeText(copiedText)
                                .then(
                                  (isSuccess) =>
                                    isSuccess && toast(`${urls.length}개의 파일 url이 복사되었습니다`, 'success'),
                                );
                            };
                            return (
                              <>
                                <Space mb={1}>
                                  <View sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                      variant={'invisible'}
                                      leadingIcon={CopyIcon}
                                      disabled={(values.file?.length ?? 0) <= 0}
                                      onClick={() => {
                                        copyAllUrl(values.file?.map(({ objectUrl }) => objectUrl ?? '') ?? []);
                                      }}
                                    >
                                      {`모든 url 복사하기`}
                                    </Button>
                                  </View>
                                </Space>
                                <UploadField
                                  label={'파일'}
                                  labelConfig={{ visuallyHidden: true }}
                                  name={'file'}
                                  caption={
                                    '· 파일 크기가 100MB보다 작은 파일을 업로드 해주세요\n· 한 번에 여러 파일을 업로드 할 수 있습니다'
                                  }
                                  multipart={false}
                                  multiple={true}
                                  onCompletedUpload={(value) => {
                                    if (!isArray(value)) return;
                                    copyAllUrl(value.map((v) => v.objectUrl ?? ''));
                                  }}
                                  uploadOptions={{
                                    //ex) uploads/2021-08-31
                                    targetDirectory: `uploads/${new Date().toISOString().split('T')[0]}`,
                                  }}
                                />
                                {(values.file?.map(({ objectUrl }) => objectUrl) ?? [])
                                  .filter((url) => url)
                                  .map((url) => (
                                    <Space key={url} mt={1}>
                                      <CopyableText fontSize={1} sx={{ wordBreak: 'break-all' }}>
                                        {url as string}
                                      </CopyableText>
                                    </Space>
                                  ))}
                              </>
                            );
                          }}
                        </Formik>
                      </Dialog.Body>
                    </Dialog>
                  )}
                >
                  {({ openDialog }) => (
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.Item onSelect={() => router.push('/setting')}>설정</ActionList.Item>
                        {me.permissions.findIndex(({ title }) => title === 'task_read') !== -1 ? (
                          <ActionList.Item onSelect={() => openDialog()}>파일 url 추출</ActionList.Item>
                        ) : null}
                        <ActionList.Item variant="danger" onClick={() => signOut({ callbackUrl: '/login' })}>
                          로그아웃
                        </ActionList.Item>
                      </ActionList>
                    </ActionMenu.Overlay>
                  )}
                </DialogHandler>
              </ActionMenu>
            </Header.Item>
          </Header>
        </PageLayout.Header>
        {isSidebarCollapsed ? null : (
          <>
            <View
              sx={{
                display: ['block', undefined, 'none'],
                backgroundColor: 'neutral.emphasisPlus',
                opacity: 0.3,
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                zIndex: 2,
              }}
              onClick={toggleIsCollapsed}
            />
            <PageLayout.Pane
              position={'start'}
              width={'small'}
              sx={{
                backgroundColor: 'canvas.default',
                overflowX: 'hidden',
                overflowY: 'scroll',
                position: 'sticky',
                top: `${LAYOUT_CONSTANTS.headerHeight}px`,
                height: ['fit-content', undefined, `calc(100vh - ${LAYOUT_CONSTANTS.headerHeight}px)`],
                maxHeight: ['40vh', undefined, `calc(100vh - ${LAYOUT_CONSTANTS.headerHeight}px)`],
                zIndex: 2,
              }}
            >
              <View
                as={'nav'}
                sx={{
                  overflowX: 'hidden',
                  backgroundColor: ['canvas.default', undefined, 'neutral.subtle'],
                  paddingY: [2, 2, 6],
                  minHeight: '100%',
                }}
              >
                <Suspense>
                  <ItemList
                    items={authenticatedRouteGroups}
                    renderItem={({ id, name, routes }) => {
                      const mainRoutes = routes.filter(({ pathname }) => pathname.split('/').slice(1).length === 1);
                      const accessibleMainRoutes = mainRoutes.filter(
                        ({ permissions }) =>
                          isNullable(permissions) ||
                          permissions.every((permission) =>
                            me.permissions.map(({ title }) => title).includes(permission as PermissionEnum),
                          ),
                      );

                      if (accessibleMainRoutes.length === 0) return null;
                      return (
                        <React.Fragment key={id}>
                          {name ? <SidebarSubHeading>{name}</SidebarSubHeading> : null}
                          <ItemList
                            items={accessibleMainRoutes}
                            renderItem={({ id, name, pathname: href, ...props }) => (
                              <SidebarNavItem key={id} href={href} {...props}>
                                {name}
                              </SidebarNavItem>
                            )}
                          />
                        </React.Fragment>
                      );
                    }}
                  />
                </Suspense>
              </View>
            </PageLayout.Pane>
          </>
        )}
        <PageLayout.Content padding={'condensed'} width={'full'} sx={{ paddingBottom: [0, 0, 280] }}>
          <View sx={{ maxWidth: '2000px', marginX: 'auto', overflow: 'clip' }}>{children}</View>
        </PageLayout.Content>
      </PageLayout>
    </HeaderSidebarLayoutContextProvider>
  );
};

const SidebarSubHeading = ({ children }: PropsWithChildren) => (
  <View sx={{ paddingTop: 3, paddingBottom: 2, paddingX: 4 }}>
    <Text fontSize={0} fontWeight={'bold'} color={'fg.subtle'}>
      {children}
    </Text>
  </View>
);

const SidebarNavItem = ({
  href = '',
  icon: Icon,
  avatarSrc,
  label,
  counter,
  status,
  children,
}: PropsWithChildren<{
  icon?: FunctionComponent<PropsWithChildren<IconProps>>;
  avatarSrc?: AvatarProps['src'];
  href?: string;
  counter?: number;
  label?: string;
  status?: boolean;
}>) => {
  const router = useRouter();
  const isCurrent = href.endsWith(router.pathname.split('/')[1]);

  return (
    <Link href={{ pathname: href }} passHref prefetch={false}>
      <Grid
        gapX={2}
        sx={{
          'paddingX': 4,
          'paddingY': 2,
          'color': isCurrent ? 'accent.fg' : 'fg.subtle',
          ':hover': { backgroundColor: 'neutral.muted' },
          '&>*': { display: 'flex', alignItems: 'center' },
        }}
      >
        {Icon ? (
          <Grid.Unit size={'min'}>
            <Icon />
          </Grid.Unit>
        ) : avatarSrc ? (
          <Grid.Unit size={'min'}>
            <Avatar src={avatarSrc} />
          </Grid.Unit>
        ) : null}
        <Grid.Unit size={'min'}>
          <Text
            sx={{
              fontWeight: ['normal', undefined, 'bold'],
              overflowWrap: 'break-word',
              fontSize: 1,
            }}
          >
            {children}
          </Text>
        </Grid.Unit>
        <Grid.Unit size={'max'} />
        {status ? (
          <Grid.Unit size={'min'}>
            <StyledOcticon icon={DotFillIcon} color={'success.fg'} />
          </Grid.Unit>
        ) : label ? (
          <Grid.Unit size={'min'}>
            <Label variant={'primary'}>{label}</Label>
          </Grid.Unit>
        ) : counter ? (
          <Grid.Unit size={'min'}>
            <CounterLabel>{counter}</CounterLabel>
          </Grid.Unit>
        ) : null}
      </Grid>
    </Link>
  );
};

const LAYOUT_CONSTANTS = {
  headerHeight: 59,
};

export default HeaderSidebarNavPageLayout;
