/**
 * @generated SignedSource<<866b6bb2776d35641cb27a37de72a43e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type ScrapSourceExamCategoryEnum = "common" | "giha" | "hwak_tong" | "mijukbun" | "none" | "type_ga" | "type_na" | "%future added value";
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type ScrapSourceExamMonthEnum = "month_1" | "month_10" | "month_11" | "month_12" | "month_2" | "month_3" | "month_4" | "month_5" | "month_6" | "month_7" | "month_8" | "month_9" | "none" | "%future added value";
export type ScrapSourceExamSourceEnum = "evaluation_test" | "military_academy" | "mock_test" | "police_academy" | "sat" | "%future added value";
export type ScrapSourceExamYearEnum = "year_2017" | "year_2018" | "year_2019" | "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "year_2026" | "year_2027" | "year_2028" | "year_2029" | "year_2030" | "%future added value";
export type TaskErrorTypeEnum = "hint" | "problem" | "%future added value";
export type TaskProblemAnswerTypeEnum = "objective" | "subjective" | "%future added value";
export type TaskProblemCreationTypeEnum = "first" | "original" | "second" | "third" | "%future added value";
export type TaskProblemTypeEnum = "copy" | "exam" | "private" | "problem_generator" | "pure" | "%future added value";
export type TaskStatusEnum = "deactivated" | "error_report" | "first_labeling" | "image_creation" | "problem_creation" | "problem_typing" | "published" | "review" | "second_labeling" | "%future added value";
export type UnitSchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type VideoTypeEnum = "horizontal" | "vertical" | "%future added value";
export type TaskFilter = {
  AND?: TaskFilter | null;
  NOT?: TaskFilter | null;
  OR?: TaskFilter | null;
  aiDifficulty_Gte?: number | null;
  aiDifficulty_Lte?: number | null;
  answerType_Exact?: TaskProblemAnswerTypeEnum | null;
  answerType_In?: ReadonlyArray<TaskProblemAnswerTypeEnum> | null;
  assignedToId_Exact?: string | null;
  assignedToId_In?: ReadonlyArray<string> | null;
  assignedTo_Exists?: boolean | null;
  assignedTo_Exists_In?: ReadonlyArray<boolean> | null;
  completedById_Exclude_In?: ReadonlyArray<string> | null;
  completedById_In?: ReadonlyArray<string> | null;
  completed_Gte?: any | null;
  completed_Lte?: any | null;
  correctResultFeedback_Published_In?: ReadonlyArray<boolean> | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  difficulty_Gte?: number | null;
  difficulty_Lte?: number | null;
  ebook_In?: ReadonlyArray<string> | null;
  errorType_In?: ReadonlyArray<TaskErrorTypeEnum> | null;
  generalSubmitFeedback_Published_In?: ReadonlyArray<boolean> | null;
  hint_Published_In?: ReadonlyArray<boolean> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  image_Exists_In?: ReadonlyArray<boolean> | null;
  incorrectResultFeedback_Published_In?: ReadonlyArray<boolean> | null;
  lastUnitDId_Exact?: string | null;
  lastUnitDId_In?: ReadonlyArray<string> | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  problemCreationType_Exact?: TaskProblemCreationTypeEnum | null;
  problemCreationType_In?: ReadonlyArray<TaskProblemCreationTypeEnum> | null;
  problemGenerator_Exists_In?: ReadonlyArray<boolean> | null;
  problemType_Exact?: TaskProblemTypeEnum | null;
  problemType_In?: ReadonlyArray<TaskProblemTypeEnum> | null;
  scrapSourceBookName_Contains?: string | null;
  scrapSourceBookPublisher_Contains?: string | null;
  scrapSourceCategory_Exact?: ScrapSourceExamCategoryEnum | null;
  scrapSourceCategory_In?: ReadonlyArray<ScrapSourceExamCategoryEnum> | null;
  scrapSourceGrade_Exact?: ScrapSourceExamGradeEnum | null;
  scrapSourceGrade_In?: ReadonlyArray<ScrapSourceExamGradeEnum> | null;
  scrapSourceMonth_Exact?: ScrapSourceExamMonthEnum | null;
  scrapSourceMonth_In?: ReadonlyArray<ScrapSourceExamMonthEnum> | null;
  scrapSourceSource_Exact?: ScrapSourceExamSourceEnum | null;
  scrapSourceSource_In?: ReadonlyArray<ScrapSourceExamSourceEnum> | null;
  scrapSourceUnitAId_Exact?: string | null;
  scrapSourceUnitAId_In?: ReadonlyArray<string> | null;
  scrapSourceYear_Exact?: ScrapSourceExamYearEnum | null;
  scrapSourceYear_In?: ReadonlyArray<ScrapSourceExamYearEnum> | null;
  search?: string | null;
  sequence_Exact?: number | null;
  sequence_In?: ReadonlyArray<number> | null;
  solutionVideoCreatedById_Exact?: string | null;
  solutionVideoCreatedById_In?: ReadonlyArray<string> | null;
  solutionVideoTeacherId_Exact?: string | null;
  solutionVideoTeacherId_In?: ReadonlyArray<string> | null;
  solutionVideoTeacherName_Exact?: string | null;
  solutionVideoTeacherName_In?: ReadonlyArray<string> | null;
  solutionVideoVideoType_Exact?: VideoTypeEnum | null;
  solutionVideoVideoType_In?: ReadonlyArray<VideoTypeEnum> | null;
  solutionVideo_Exists?: boolean | null;
  solutionVideo_Exists_In?: ReadonlyArray<boolean> | null;
  specialSubmitFeedback_Published_In?: ReadonlyArray<boolean> | null;
  status_Exact?: TaskStatusEnum | null;
  status_In?: ReadonlyArray<TaskStatusEnum> | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  typingAutomationScript_Exists_In?: ReadonlyArray<boolean> | null;
  unitAId_Exact?: string | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitBId_Exact?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitCId_Exact?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitDId_Exact?: string | null;
  unitDId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: UnitSchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<UnitSchoolTypeEnum> | null;
};
export type TaskOrder = {
  assigned?: Ordering | null;
  completed?: Ordering | null;
  created?: Ordering | null;
  description?: Ordering | null;
  difficulty?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
  sequence?: Ordering | null;
  title?: Ordering | null;
  unitD_Order?: Ordering | null;
};
export type task_tasksQuery$variables = {
  filters?: TaskFilter | null;
  order?: TaskOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type task_tasksQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TaskWindowPaginator_query">;
};
export type task_tasksQuery = {
  response: task_tasksQuery$data;
  variables: task_tasksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "picture",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeleted",
    "storageKey": null
  }
],
v8 = [
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "task_tasksQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "TaskWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "task_tasksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TaskConnection",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskProblem",
                    "kind": "LinkedField",
                    "name": "problem",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "problemType",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnitA",
                    "kind": "LinkedField",
                    "name": "unitA",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnitB",
                    "kind": "LinkedField",
                    "name": "unitB",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnitC",
                    "kind": "LinkedField",
                    "name": "unitC",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnitD",
                    "kind": "LinkedField",
                    "name": "unitD",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "assignedTo",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "completedBy",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "difficulty",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProblemGenerator",
                    "kind": "LinkedField",
                    "name": "problemGenerator",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskSolutionVideo",
                    "kind": "LinkedField",
                    "name": "solutionVideo",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskHint",
                    "kind": "LinkedField",
                    "name": "hint",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publishedText",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskSubmitFeedback",
                    "kind": "LinkedField",
                    "name": "submitFeedback",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publishedSpecial",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publishedGeneral",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TypingAutomationScript",
                    "kind": "LinkedField",
                    "name": "typingAutomationScript",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3769792973f02e6e4dc179624d0fb5f6",
    "id": null,
    "metadata": {},
    "name": "task_tasksQuery",
    "operationKind": "query",
    "text": "query task_tasksQuery(\n  $filters: TaskFilter\n  $order: TaskOrder\n  $page: Int\n  $pageSize: Int\n) {\n  ...TaskWindowPaginator_query_36liK3\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment TaskConnectionDataTable_taskConnection on TaskConnection {\n  edges {\n    node {\n      id\n      sequence\n      status\n      ...TaskStatusLabel_task\n      problem {\n        id\n        problemType\n      }\n      title\n      unitA {\n        id\n        title\n      }\n      unitB {\n        id\n        title\n      }\n      unitC {\n        id\n        title\n      }\n      unitD {\n        id\n        description\n      }\n      assignedTo {\n        id\n        ...UserAvatarText_user\n      }\n      completedBy {\n        id\n        ...UserAvatarText_user\n      }\n      completed\n      difficulty\n      problemGenerator {\n        id\n      }\n      solutionVideo {\n        id\n      }\n      hint {\n        id\n        publishedText\n      }\n      submitFeedback {\n        id\n        publishedSpecial\n        publishedGeneral\n      }\n      typingAutomationScript {\n        id\n      }\n    }\n  }\n}\n\nfragment TaskStatusLabel_task on Task {\n  status\n}\n\nfragment TaskWindowPaginator_query_36liK3 on Query {\n  tasks(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n        status\n      }\n    }\n    ...TaskConnectionDataTable_taskConnection\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "cb56973d517e61c28d49ad89bd64d686";

export default node;
