import { format, parseISO } from 'date-fns';
import { graphql, useFragment } from 'react-relay';

import {
  UserInvoiceConnectionDataTable_UserInvoiceConnection$data,
  UserInvoiceConnectionDataTable_UserInvoiceConnection$key,
} from '../../../relay/__generated__/UserInvoiceConnectionDataTable_UserInvoiceConnection.graphql';
import { numberWithCommas } from '../../../utils/number';
import DataTable, { DataTableProps } from '../../core/DataTable';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';

const UserInvoiceConnectionDataTable_UserInvoiceConnection = graphql`
  fragment UserInvoiceConnectionDataTable_UserInvoiceConnection on UserInvoiceConnection {
    edges {
      node {
        id
        product {
          name
          item {
            premiumType
          }
        }
        order {
          paidAt
          amount
          applyStartDate
          applyEndDate
        }
      }
    }
  }
`;

type Props = {
  UserInvoiceConnection: UserInvoiceConnectionDataTable_UserInvoiceConnection$key;
} & Omit<
  DataTableProps<UserInvoiceConnectionDataTable_UserInvoiceConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const UserInvoiceConnectionDataTable = ({ UserInvoiceConnection, ...props }: Props) => {
  const { edges } = useFragment(UserInvoiceConnectionDataTable_UserInvoiceConnection, UserInvoiceConnection);

  return (
    <DataTable
      rows={edges.map((edge) => edge.node)}
      columns={[
        {
          field: 'product.name',
          title: '상품명',
          renderValue: ({ product }) => <Text fontSize={1}> {product.name} </Text>,
          width: 80,
          align: 'center',
        },
        {
          field: 'product.item.premiumType',
          title: '상품 타입',
          renderValue: ({ product }) => (
            <Text fontSize={1}>
              <EnumPair typename={'PremiumTypeEnum'}>{product.item.premiumType}</EnumPair>{' '}
            </Text>
          ),
          width: 80,
          align: 'center',
        },
        {
          field: 'order.amount',
          title: '금액',
          renderValue: ({ order }) => <Text fontSize={1}> {numberWithCommas(order.amount)} </Text>,
          width: 80,
          align: 'center',
        },
        {
          field: 'order.paidAt',
          title: '결제일',
          renderValue: ({ order }) => <Text fontSize={1}> {format(parseISO(order.paidAt), 'yyyy-MM-dd')} </Text>,
          width: 80,
          align: 'center',
        },
        {
          field: 'order.applyStartDate',
          title: '적용 시작일',
          renderValue: ({ order }) => <Text fontSize={1}> {order.applyStartDate} </Text>,
          width: 80,
          align: 'center',
        },
        {
          field: 'order.applyEndDate',
          title: '적용 종료일',
          renderValue: ({ order }) => <Text fontSize={1}> {order.applyEndDate} </Text>,
          width: 80,
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};

export default UserInvoiceConnectionDataTable;
