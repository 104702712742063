import { graphql, useFragment } from 'react-relay';

import { InternalUserStatusMessageUpdateDialog_internalUser$key } from '../../../relay/__generated__/InternalUserStatusMessageUpdateDialog_internalUser.graphql';
import { InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation } from '../../../relay/__generated__/InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const InternalUserStatusMessageUpdateDialog_internalUser = graphql`
  fragment InternalUserStatusMessageUpdateDialog_internalUser on InternalUser {
    id
    studentProfile {
      statusMessage
    }
  }
`;

type Props = {
  internalUser: InternalUserStatusMessageUpdateDialog_internalUser$key;
} & DialogProps &
  Pick<
    MutationFormikProps<InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation>,
    'config' | 'onSubmit'
  >;

const InternalUserStatusMessageUpdateDialog = ({ internalUser, onSubmit, config, ...props }: Props) => {
  const data = useFragment(InternalUserStatusMessageUpdateDialog_internalUser, internalUser);
  const { id, studentProfile } = data;
  const { statusMessage } = studentProfile;
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>유저 상태메시지 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation>
        mutation={graphql`
          mutation InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation(
            $input: InternalUserStatusMessageUpdateInput!
          ) {
            internalUserStatusMessageUpdate(input: $input) {
              id
              studentProfile {
                statusMessage
              }
            }
          }
        `}
        initialValues={{
          id: id,
          statusMessage: statusMessage || '',
        }}
        onSubmit={onSubmit}
        config={config}
      >
        {({ submitForm, dirty }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField label={'상태메시지'} name={'statusMessage'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button onClick={submitForm} disabled={!dirty}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default InternalUserStatusMessageUpdateDialog;
