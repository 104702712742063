/**
 * @generated SignedSource<<55f7876335225c8a7d0a55d90b7aecb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type SchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitSchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitCFilter = {
  AND?: UnitCFilter | null;
  DISTINCT?: boolean | null;
  NOT?: UnitCFilter | null;
  OR?: UnitCFilter | null;
  created_Gte?: any | null;
  created_Lte?: any | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  modified_Gte?: any | null;
  modified_Lte?: any | null;
  schoolType_In?: ReadonlyArray<SchoolTypeEnum> | null;
  search?: string | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: UnitSchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<UnitSchoolTypeEnum> | null;
  unitTitle_Contains?: string | null;
};
export type UnitCOrder = {
  id?: Ordering | null;
  name?: Ordering | null;
  order?: Ordering | null;
  title?: Ordering | null;
};
export type UnitCSelectField_unitCsQuery$variables = {
  filters?: UnitCFilter | null;
  order?: UnitCOrder | null;
};
export type UnitCSelectField_unitCsQuery$data = {
  readonly unitCs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
      };
    }>;
  };
};
export type UnitCSelectField_unitCsQuery = {
  response: UnitCSelectField_unitCsQuery$data;
  variables: UnitCSelectField_unitCsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      }
    ],
    "concreteType": "UnitCConnection",
    "kind": "LinkedField",
    "name": "unitCs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnitCEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitC",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitCSelectField_unitCsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnitCSelectField_unitCsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "81e159414ed9e16182f20d488db20e0a",
    "id": null,
    "metadata": {},
    "name": "UnitCSelectField_unitCsQuery",
    "operationKind": "query",
    "text": "query UnitCSelectField_unitCsQuery(\n  $filters: UnitCFilter\n  $order: UnitCOrder\n) {\n  unitCs(filters: $filters, order: $order) {\n    edges {\n      node {\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aecc368333224f3dd695fb200a9e17bb";

export default node;
