/**
 * @generated SignedSource<<f9800b0fea0260128711eadccfbff195>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InternalLoginTypeUpdateDialog_internalUser$data = {
  readonly id: string;
  readonly loginId: string;
  readonly " $fragmentType": "InternalLoginTypeUpdateDialog_internalUser";
};
export type InternalLoginTypeUpdateDialog_internalUser$key = {
  readonly " $data"?: InternalLoginTypeUpdateDialog_internalUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternalLoginTypeUpdateDialog_internalUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternalLoginTypeUpdateDialog_internalUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loginId",
      "storageKey": null
    }
  ],
  "type": "InternalUser",
  "abstractKey": null
};

(node as any).hash = "a520d8ae16b74ea1974bf9530c54f520";

export default node;
