/**
 * @generated SignedSource<<3f6ad4745254a45d795366292fe65421>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PremiumUpdateDialog_premium$data = {
  readonly endAt: any;
  readonly id: string;
  readonly startAt: any;
  readonly " $fragmentType": "PremiumUpdateDialog_premium";
};
export type PremiumUpdateDialog_premium$key = {
  readonly " $data"?: PremiumUpdateDialog_premium$data;
  readonly " $fragmentSpreads": FragmentRefs<"PremiumUpdateDialog_premium">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PremiumUpdateDialog_premium",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAt",
      "storageKey": null
    }
  ],
  "type": "PremiumApplyHistory",
  "abstractKey": null
};

(node as any).hash = "65b8970e0f8898d5d2cd2b73fae3ff8a";

export default node;
