/**
 * @generated SignedSource<<eab9ed651ef3dcf6031615b851223e10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SchoolYearMajorTypeEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1_common" | "high_school_2_common" | "high_school_3_giha" | "high_school_3_hwaktong" | "high_school_3_mijukbun" | "high_school_n_giha" | "high_school_n_hwaktong" | "high_school_n_mijukbun" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
export type InternalSchoolYearMajorTypeUpdateInput = {
  id: string;
  schoolYearMajorType: SchoolYearMajorTypeEnum;
};
export type InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation$variables = {
  input: InternalSchoolYearMajorTypeUpdateInput;
};
export type InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation$data = {
  readonly internalStudentTypeUpdate: {
    readonly id: string;
    readonly studentProfile: {
      readonly schoolYearMajorType: SchoolYearMajorTypeEnum;
    };
  };
};
export type InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation = {
  response: InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation$data;
  variables: InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InternalUser",
    "kind": "LinkedField",
    "name": "internalStudentTypeUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InternalStudentProfile",
        "kind": "LinkedField",
        "name": "studentProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolYearMajorType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8f569813f0a69232d873074c08c364de",
    "id": null,
    "metadata": {},
    "name": "InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation(\n  $input: InternalSchoolYearMajorTypeUpdateInput!\n) {\n  internalStudentTypeUpdate(input: $input) {\n    id\n    studentProfile {\n      schoolYearMajorType\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "720479e185cf063591f793145efb6b48";

export default node;
