/**
 * @generated SignedSource<<ffcdf303494d343bae4eb69d349d89af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type StoreStuffTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
export type StoreStuffFilter = {
  AND?: StoreStuffFilter | null;
  NOT?: StoreStuffFilter | null;
  OR?: StoreStuffFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  endAt_Exact?: any | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  isValid?: boolean | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  period_In?: ReadonlyArray<string> | null;
  search?: string | null;
  type_In?: ReadonlyArray<StoreStuffTypeEnum> | null;
};
export type StoreStuffOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
};
export type StoreStuffWindowPaginatorRefreshQuery$variables = {
  filters?: StoreStuffFilter | null;
  order?: StoreStuffOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type StoreStuffWindowPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StoreStuffWindowPaginator_query">;
};
export type StoreStuffWindowPaginatorRefreshQuery = {
  response: StoreStuffWindowPaginatorRefreshQuery$data;
  variables: StoreStuffWindowPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StoreStuffWindowPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "StoreStuffWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StoreStuffWindowPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StoreStuffConnection",
        "kind": "LinkedField",
        "name": "storeStuffs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreStuffEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreStuff",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "period",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "picture",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDeleted",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "modified",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "861e08f4724d3252a38e55a61fae6328",
    "id": null,
    "metadata": {},
    "name": "StoreStuffWindowPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query StoreStuffWindowPaginatorRefreshQuery(\n  $filters: StoreStuffFilter\n  $order: StoreStuffOrder\n  $page: Int = 1\n  $pageSize: Int = 20\n) {\n  ...StoreStuffWindowPaginator_query_36liK3\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment StoreStuffConnectionDataTable_storeStuffConnection on StoreStuffConnection {\n  edges {\n    node {\n      id\n      sequence\n      title\n      type\n      period\n      endAt\n      createdBy {\n        id\n        ...UserAvatarText_user\n      }\n      modified\n    }\n  }\n}\n\nfragment StoreStuffWindowPaginator_query_36liK3 on Query {\n  storeStuffs(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...StoreStuffConnectionDataTable_storeStuffConnection\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "d1b934e746887bb59a0c4e381afc8e14";

export default node;
