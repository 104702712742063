/**
 * @generated SignedSource<<d087cb6eebbf82279deae9c075ce1fb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PremiumTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
export type InternalPremiumApplyHistoryCreateInput = {
  endAt: any;
  itemName: string;
  premiumType: PremiumTypeEnum;
  startAt: any;
  userId: string;
};
export type PremiumCreateDialog_premiumCreateMutation$variables = {
  input: InternalPremiumApplyHistoryCreateInput;
};
export type PremiumCreateDialog_premiumCreateMutation$data = {
  readonly internalPremiumApplyHistoryCreate: {
    readonly created: any;
    readonly endAt: any;
    readonly id: string;
    readonly item: {
      readonly id: string;
      readonly name: string;
      readonly premiumType: PremiumTypeEnum;
    };
    readonly startAt: any;
    readonly user: {
      readonly id: string;
    };
  };
};
export type PremiumCreateDialog_premiumCreateMutation = {
  response: PremiumCreateDialog_premiumCreateMutation$data;
  variables: PremiumCreateDialog_premiumCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PremiumApplyHistory",
    "kind": "LinkedField",
    "name": "internalPremiumApplyHistoryCreate",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InternalItem",
        "kind": "LinkedField",
        "name": "item",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premiumType",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InternalUser",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PremiumCreateDialog_premiumCreateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PremiumCreateDialog_premiumCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a8a06001e157ae2c7a5d204dafa624d3",
    "id": null,
    "metadata": {},
    "name": "PremiumCreateDialog_premiumCreateMutation",
    "operationKind": "mutation",
    "text": "mutation PremiumCreateDialog_premiumCreateMutation(\n  $input: InternalPremiumApplyHistoryCreateInput!\n) {\n  internalPremiumApplyHistoryCreate(input: $input) {\n    id\n    startAt\n    endAt\n    created\n    item {\n      id\n      name\n      premiumType\n    }\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0106e6728368a81c52df6d52b989a6f";

export default node;
