/**
 * @generated SignedSource<<24e1fb99ae34be6da36b2229cbd682d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type SchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitSchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitCFilter = {
  AND?: UnitCFilter | null;
  DISTINCT?: boolean | null;
  NOT?: UnitCFilter | null;
  OR?: UnitCFilter | null;
  created_Gte?: any | null;
  created_Lte?: any | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  modified_Gte?: any | null;
  modified_Lte?: any | null;
  schoolType_In?: ReadonlyArray<SchoolTypeEnum> | null;
  search?: string | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: UnitSchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<UnitSchoolTypeEnum> | null;
  unitTitle_Contains?: string | null;
};
export type UnitCOrder = {
  id?: Ordering | null;
  name?: Ordering | null;
  order?: Ordering | null;
  title?: Ordering | null;
};
export type UnitCPaginatorRefreshQuery$variables = {
  after?: string | null;
  filters?: UnitCFilter | null;
  first?: number | null;
  order?: UnitCOrder | null;
};
export type UnitCPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UnitCPaginator_query">;
};
export type UnitCPaginatorRefreshQuery = {
  response: UnitCPaginatorRefreshQuery$data;
  variables: UnitCPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitCPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UnitCPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnitCPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnitCConnection",
        "kind": "LinkedField",
        "name": "unitCs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitCEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UnitC",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "UnitCPaginator_query_unitCs",
        "kind": "LinkedHandle",
        "name": "unitCs"
      }
    ]
  },
  "params": {
    "cacheID": "482de847a7c1dddbe6a8acd40b397dd7",
    "id": null,
    "metadata": {},
    "name": "UnitCPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query UnitCPaginatorRefreshQuery(\n  $after: String\n  $filters: UnitCFilter\n  $first: Int\n  $order: UnitCOrder\n) {\n  ...UnitCPaginator_query_2rfEMg\n}\n\nfragment UnitCPaginator_query_2rfEMg on Query {\n  unitCs(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        title\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c20de12f5e33da2d4b54fb63e03ffc1";

export default node;
