/**
 * @generated SignedSource<<88780aa5e7e2dc2115564b570aea2b61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type StuffTypeEnum = "coupon" | "delivery" | "gifticon" | "premium_non_refundable" | "premium_refundable" | "%future added value";
export type StuffFilter = {
  AND?: StuffFilter | null;
  NOT?: StuffFilter | null;
  OR?: StuffFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  search?: string | null;
  type_In?: ReadonlyArray<StuffTypeEnum> | null;
};
export type StuffOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
};
export type StuffPaginatorRefreshQuery$variables = {
  after?: string | null;
  filters?: StuffFilter | null;
  first?: number | null;
  order?: StuffOrder | null;
};
export type StuffPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StuffPaginator_query">;
};
export type StuffPaginatorRefreshQuery = {
  response: StuffPaginatorRefreshQuery$data;
  variables: StuffPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StuffPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "StuffPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StuffPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StuffConnection",
        "kind": "LinkedField",
        "name": "stuffs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StuffEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stuff",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "StuffPaginator_query_stuffs",
        "kind": "LinkedHandle",
        "name": "stuffs"
      }
    ]
  },
  "params": {
    "cacheID": "299f2f0acc14fbfff3ccd09db73b404d",
    "id": null,
    "metadata": {},
    "name": "StuffPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query StuffPaginatorRefreshQuery(\n  $after: String\n  $filters: StuffFilter\n  $first: Int\n  $order: StuffOrder\n) {\n  ...StuffPaginator_query_2rfEMg\n}\n\nfragment StuffPaginator_query_2rfEMg on Query {\n  stuffs(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        title\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b04a646b28a99e8a0be7ffba2b9c4773";

export default node;
