/**
 * @generated SignedSource<<3a71f73b8efd7fed4b80345480b93570>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppDialogStatusEnum = "finished" | "ongoing" | "pending" | "%future added value";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type AppDialogFilter = {
  AND?: AppDialogFilter | null;
  NOT?: AppDialogFilter | null;
  OR?: AppDialogFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  endAt_Gte?: any | null;
  endAt_Lte?: any | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  search?: string | null;
  startAt_Gte?: any | null;
  startAt_Lte?: any | null;
  status_In?: ReadonlyArray<AppDialogStatusEnum> | null;
};
export type AppDialogOrder = {
  created?: Ordering | null;
  endAt?: Ordering | null;
  modified?: Ordering | null;
  startAt?: Ordering | null;
};
export type appDialog_appDialogsQuery$variables = {
  filters?: AppDialogFilter | null;
  order?: AppDialogOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type appDialog_appDialogsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AppDialogWindowPaginator_query">;
};
export type appDialog_appDialogsQuery = {
  response: appDialog_appDialogsQuery$data;
  variables: appDialog_appDialogsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "appDialog_appDialogsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "AppDialogWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "appDialog_appDialogsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppDialogConnection",
        "kind": "LinkedField",
        "name": "appDialogs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AppDialogEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AppDialog",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "order",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "picture",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDeleted",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4a299d24bbf79bf928d2acd7cd54f90a",
    "id": null,
    "metadata": {},
    "name": "appDialog_appDialogsQuery",
    "operationKind": "query",
    "text": "query appDialog_appDialogsQuery(\n  $filters: AppDialogFilter\n  $order: AppDialogOrder\n  $page: Int\n  $pageSize: Int\n) {\n  ...AppDialogWindowPaginator_query_36liK3\n}\n\nfragment AppDialogConnectionDataTable_appDialogConnection on AppDialogConnection {\n  edges {\n    node {\n      id\n      title\n      sequence\n      startAt\n      endAt\n      order\n      createdBy {\n        ...UserAvatarText_user\n        id\n      }\n      ...AppDialogStatusLabel_appDialog\n    }\n  }\n}\n\nfragment AppDialogStatusLabel_appDialog on AppDialog {\n  status\n}\n\nfragment AppDialogWindowPaginator_query_36liK3 on Query {\n  appDialogs(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...AppDialogConnectionDataTable_appDialogConnection\n  }\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "50ae07d0abfdccfc8b8cbd65de063ae9";

export default node;
