/**
 * @generated SignedSource<<07a0701e156a370c0dc5e1a5d8bec927>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type SchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitSchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitDFilter = {
  AND?: UnitDFilter | null;
  DISTINCT?: boolean | null;
  NOT?: UnitDFilter | null;
  OR?: UnitDFilter | null;
  created_Gte?: any | null;
  created_Lte?: any | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  modified_Gte?: any | null;
  modified_Lte?: any | null;
  schoolType_In?: ReadonlyArray<SchoolTypeEnum> | null;
  search?: string | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitA_Exact?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitCTitle_Contains?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: UnitSchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<UnitSchoolTypeEnum> | null;
  unitTitle_Contains?: string | null;
};
export type UnitDOrder = {
  description?: Ordering | null;
  id?: Ordering | null;
  name?: Ordering | null;
  order?: Ordering | null;
  title?: Ordering | null;
};
export type UnitDSelectDialogTokenField_unitDsQuery$variables = {
  filters?: UnitDFilter | null;
  order?: UnitDOrder | null;
};
export type UnitDSelectDialogTokenField_unitDsQuery$data = {
  readonly unitDs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string | null;
      };
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"UnitDConnectionDataTable_unitDConnection">;
  };
};
export type UnitDSelectDialogTokenField_unitDsQuery = {
  response: UnitDSelectDialogTokenField_unitDsQuery$data;
  variables: UnitDSelectDialogTokenField_unitDsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "title",
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitDSelectDialogTokenField_unitDsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnitDConnection",
        "kind": "LinkedField",
        "name": "unitDs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitDEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnitD",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnitDConnectionDataTable_unitDConnection"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnitDSelectDialogTokenField_unitDsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnitDConnection",
        "kind": "LinkedField",
        "name": "unitDs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitDEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnitD",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitBTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitCTitle",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "81aab3172e132d88e852c1b4833981be",
    "id": null,
    "metadata": {},
    "name": "UnitDSelectDialogTokenField_unitDsQuery",
    "operationKind": "query",
    "text": "query UnitDSelectDialogTokenField_unitDsQuery(\n  $filters: UnitDFilter\n  $order: UnitDOrder\n) {\n  unitDs(filters: $filters, order: $order) {\n    edges {\n      node {\n        id\n        title: description\n      }\n    }\n    ...UnitDConnectionDataTable_unitDConnection\n  }\n}\n\nfragment UnitDConnectionDataTable_unitDConnection on UnitDConnection {\n  edges {\n    node {\n      id\n      unitBTitle\n      unitCTitle\n      title: description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "28a0bf69ee3a69b9199b918276ce0431";

export default node;
