/**
 * @generated SignedSource<<b7a52745063088da06ecba90a074e634>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type ScrapSourceExamCategoryEnum = "common" | "giha" | "hwak_tong" | "mijukbun" | "none" | "type_ga" | "type_na" | "%future added value";
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type ScrapSourceExamMonthEnum = "month_1" | "month_10" | "month_11" | "month_12" | "month_2" | "month_3" | "month_4" | "month_5" | "month_6" | "month_7" | "month_8" | "month_9" | "none" | "%future added value";
export type ScrapSourceExamSourceEnum = "evaluation_test" | "military_academy" | "mock_test" | "police_academy" | "sat" | "%future added value";
export type ScrapSourceExamYearEnum = "year_2017" | "year_2018" | "year_2019" | "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "year_2026" | "year_2027" | "year_2028" | "year_2029" | "year_2030" | "%future added value";
export type ScrapSourceStatusEnum = "completed" | "ongoing" | "%future added value";
export type ScrapSourceTypeEnum = "book" | "exam" | "%future added value";
export type ScrapSourceFilter = {
  AND?: ScrapSourceFilter | null;
  NOT?: ScrapSourceFilter | null;
  OR?: ScrapSourceFilter | null;
  bookName_Contains?: string | null;
  bookPublisher_Contains?: string | null;
  category_Exact?: ScrapSourceExamCategoryEnum | null;
  category_In?: ReadonlyArray<ScrapSourceExamCategoryEnum> | null;
  grade_Exact?: ScrapSourceExamGradeEnum | null;
  grade_In?: ReadonlyArray<ScrapSourceExamGradeEnum> | null;
  month_Exact?: ScrapSourceExamMonthEnum | null;
  month_In?: ReadonlyArray<ScrapSourceExamMonthEnum> | null;
  search?: string | null;
  source_Exact?: ScrapSourceExamSourceEnum | null;
  source_In?: ReadonlyArray<ScrapSourceExamSourceEnum> | null;
  status_Exact?: ScrapSourceStatusEnum | null;
  status_In?: ReadonlyArray<ScrapSourceStatusEnum> | null;
  type_Exact?: ScrapSourceTypeEnum | null;
  type_In?: ReadonlyArray<ScrapSourceTypeEnum> | null;
  unitAId_Exact?: string | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitId_Exact?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  year_Exact?: ScrapSourceExamYearEnum | null;
  year_In?: ReadonlyArray<ScrapSourceExamYearEnum> | null;
};
export type ScrapSourceOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  sequence?: Ordering | null;
};
export type ScrapSourceWindowPaginatorRefreshQuery$variables = {
  filters?: ScrapSourceFilter | null;
  order?: ScrapSourceOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type ScrapSourceWindowPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceWindowPaginator_query">;
};
export type ScrapSourceWindowPaginatorRefreshQuery = {
  response: ScrapSourceWindowPaginatorRefreshQuery$data;
  variables: ScrapSourceWindowPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "picture",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeleted",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScrapSourceWindowPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ScrapSourceWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScrapSourceWindowPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScrapSourceConnection",
        "kind": "LinkedField",
        "name": "scrapSources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ScrapSourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScrapSource",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "submittedBy",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "16fad9f1cac8dc9a09e608fed13c444c",
    "id": null,
    "metadata": {},
    "name": "ScrapSourceWindowPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query ScrapSourceWindowPaginatorRefreshQuery(\n  $filters: ScrapSourceFilter\n  $order: ScrapSourceOrder\n  $page: Int = 1\n  $pageSize: Int = 20\n) {\n  ...ScrapSourceWindowPaginator_query_36liK3\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment ScrapSourceConnectionDataTable_scrapSourceConnection on ScrapSourceConnection {\n  edges {\n    node {\n      id\n      ...ScrapSourceStatusLabel_scrapSource\n      sequence\n      type\n      title\n      created\n      submittedBy {\n        id\n        ...UserAvatarText_user\n      }\n      createdBy {\n        id\n        ...UserAvatarText_user\n      }\n    }\n  }\n}\n\nfragment ScrapSourceStatusLabel_scrapSource on ScrapSource {\n  status\n}\n\nfragment ScrapSourceWindowPaginator_query_36liK3 on Query {\n  scrapSources(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...ScrapSourceConnectionDataTable_scrapSourceConnection\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "73e21e1a60b2169cf924624673eee966";

export default node;
