import { graphql } from 'react-relay';

import { UserInvoicePaginator_query$key } from '../../../relay/__generated__/UserInvoicePaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const UserInvoicePaginator_query = graphql`
  fragment UserInvoicePaginator_query on Query
  @argumentDefinitions(
    filters: { type: UserInvoiceFilter, defaultValue: { isPaid: true } }
    order: { type: UserInvoiceOrder, defaultValue: { order_PaidAt: DESC } }
    first: { type: Int, defaultValue: 5 }
    after: { type: String }
  )
  @refetchable(queryName: "UserInvoicePaginatorRefreshQuery") {
    userInvoices(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "UserInvoicePaginator_query_userInvoices") {
      __id
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          product {
            name
            item {
              premiumType
            }
          }
          order {
            paidAt
            amount
            applyStartDate
            applyEndDate
          }
        }
      }
      ...UserInvoiceConnectionDataTable_UserInvoiceConnection
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<UserInvoicePaginator_query$key>, 'fragment'>;

const UserInvoicePaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={UserInvoicePaginator_query} {...props} />
);

export default UserInvoicePaginator;
